import React, { useState, useEffect } from 'react';
import { Blurhash } from 'react-blurhash';
import { useNavigate } from 'react-router-dom';
import "./hero.css";

const HeroSection = ({
    bgImage, 
    title, 
    subtitle, 
    headinglevel, 
    headingleveltwo, 
    path,
    titleFont = 'Audiowide', 
    subtitleFont = 'Roboto Slab',
    blurhash = 'LEHV6nWB2yk8pyo0adR*.7kCMdnj' // Default blurhash, replace with actual one
}) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setImageLoaded(true);
        };
        img.src = bgImage;
    }, [bgImage]);

    const heroStyle = {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        position: 'relative'
    };

    if (imageLoaded) {
        heroStyle.backgroundImage = `url(${bgImage})`;
    }

    const titleStyle = {
        fontFamily: titleFont
    };

    const subtitleStyle = {
        fontFamily: subtitleFont
    };

    const handleClick = () => {
        navigate(path);
    };

    return (
        <div id="hero">
            <div className="hero-container container-fluid" style={heroStyle}>
                {!imageLoaded && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        overflow: 'hidden',
                    }}>
                        <Blurhash
                            hash={blurhash}
                            width="100%"
                            height="100%"
                            resolutionX={32}
                            resolutionY={32}
                            punch={1}
                        />
                    </div>
                )}
                <h1 className="title" style={titleStyle}>
                    {title}
                </h1>
                <h1 className="subtitle" style={titleStyle}>
                    {subtitle}
                </h1>
                <h2 className="headinglevel" style={subtitleStyle}>
                    {headinglevel}
                    <br />
                    {headingleveltwo}
                </h2>
                <button className="know-more-btn" onClick={handleClick}>Know more</button>
            </div>
        </div>
    );
};

export default HeroSection;