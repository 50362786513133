import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './partner.css';  // Assuming you have saved your CSS here

const ClientCarousel = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            }
        ]
    };

    return (
        <div className="client">
            <div className="container-box">
                <div className="row">
                    <div className="section-title">
                        <h2>Our Ecosystem Partners</h2>
                    </div>
                    <Slider {...settings}>
                        <div className="slide"><img src={require("../../assets/images/partners/IMPunjab-Logo-1.webp")} alt="Client Logo 1" className='logo-image' /></div>
                        <div className="slide"><img src={require("../../assets/images/partners/nsc.png")} alt="Client Logo 3" className='logo-image' /></div>
                        <div className="slide"><img src={require("../../assets/images/partners/pu-logo.png")} alt="Client Logo 5" className='logo-image' /></div>
                        <div className="slide"><img src={require("../../assets/images/partners/startup-punjab.png")} alt="Client Logo 6" className='logo-image' /></div>
                        <div className="slide"><img src={require("../../assets/images/partners/IIT-Loog.webp")} alt="Client Logo 6" className='logo-image' /></div>
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default ClientCarousel;
