import React, { useState, useEffect } from 'react';
import "./about.css";

const ManufacturingAbout = (props) => {
    const { imageurl, industries } = props; // Assuming 'industries' is an array of objects passed as a prop

    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div id="about" className="about">
            <div className="container-fluid">
                { isMobile ? 
                    <div className="row">
                        <div className="col-lg-7 pt-4 pt-lg-0 order-2 order-lg-1 content">
                            <h2 className='who-we-are-heading'>{"Industries We Serve"}</h2>
                            <div className="col-lg-5 order-1 order-lg-2 d-flex justify-content-center image-container">
                                <img src={imageurl} className="img-fluid about-image" alt="Industries we serve" />
                            </div>
                            {industries.map((industry, index) => (
                                <div key={index} className="industry">
                                    <div className="industry-description">
                                        <span style={{fontWeight:'bold'}}>{industry.heading}</span> {industry.description}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                :
                    <div className="row">
                        <div className="col-lg-5 order-1 order-lg-2 d-flex justify-content-center image-container">
                            <img src={imageurl} className="img-fluid about-image" alt="Industries we serve" />
                        </div>
                        <div className="col-lg-7 pt-4 pt-lg-0 order-2 order-lg-1 content">
                            <h2 className='who-we-are-heading'>{"Industries We Serve"}</h2>
                            {industries.map((industry, index) => (
                                <div key={index} className="industry">
                                    <div className="industry-description">
                                        <span style={{fontWeight:'bold'}}>{industry.heading}</span> {industry.description}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }   
            </div>
        </div>
    )
}

export default ManufacturingAbout;
