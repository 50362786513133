import React, { useState } from 'react';
import { Blurhash } from 'react-blurhash';
import "./groupcard.css";

const GroupCard = ({ cards }) => {
    const [imageLoaded, setImageLoaded] = useState({});

    const handleImageLoad = (index, img) => {
        setImageLoaded((prev) => ({
            ...prev,
            [index]: true,
        }));
    };

    return (
        <div id="about-boxes" className="about-boxes">
            <div className="container-fluid" data-aos="fade-up">
                <div className="row g-10">
                    {cards.map((card, index) => (
                        <div 
                            key={index} 
                            className="col-lg-4 col-md-6 col-sm-12 d-flex align-items-center justify-content-center card-box" 
                            data-aos-delay={`${100 + index * 100}`}
                        >
                            <div className="card" style={{ borderRadius: 15 }}>
                            {!imageLoaded[index] && card.blurhash && card.blurhash.length >= 6 && (
                                    <div id="card-image" className="p-2">
                                        <Blurhash
                                            hash={card.blurhash} 
                                            width='100%'
                                            height='100%' 
                                            punch={1}
                                        />
                                    </div>
                                )}

                                <img 
                                    src={card.imageUrl} 
                                    className="card-img-top p-2" 
                                    id="card-image" 
                                    alt={card.title} 
                                    style={{ display: imageLoaded[index] ? 'block' : 'none' }}
                                    onLoad={(e) => handleImageLoad(index, e.target)}
                                />

                                <div className="card-body">
                                    <h5 className="card-title">
                                        <p style={{ color: 'black' }}>{card.title}</p>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GroupCard;
