import React, { useState, useEffect } from 'react';
import './tophero.css';

const TopheroSection = () => {

    return (
        <div id='hero-top' className='hero-top'>
            <div className="overlay-div">
                <div className="circle-content">
                    <p className='heading-text'>TERAFAC</p>
                </div>
            </div>
        </div>
    );
};

export default TopheroSection;
