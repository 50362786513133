import React, { useEffect, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import './manufacturinghero.css';

const ManufacturingHero = ({ 
  backgroundImage, 
  backgroundColor, 
  text = [], 
  className, 
  height = { default: '100vh', mobile: '50vh' }, 
  borderRadius, 
  backgroundSize = "cover", 
  border, 
  width,
  blurhash = "LEHV6nWB2yk8pyo0adR*.7kCMdnj" // Default blurhash
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = backgroundImage;
  }, [backgroundImage]);

  const getHeight = (heightObj) => {
    const screenWidth = window.innerWidth;
    return screenWidth < 768 ? heightObj.mobile || heightObj.default : heightObj.default;
  };

  const containerStyle = {
    height: getHeight(height),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
    backgroundSize: backgroundSize,
    backgroundPosition: 'center',
    borderRadius: borderRadius,
    position: 'relative',
  };

  if (imageLoaded) {
    containerStyle.backgroundImage = backgroundColor
      ? `linear-gradient(${backgroundColor}, ${backgroundColor}), url(${backgroundImage})`
      : `url(${backgroundImage})`;
  }

  const getFontSize = (fontSizeObj) => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 2560) return fontSizeObj.large;
    if (screenWidth < 768) return fontSizeObj.mobile || fontSizeObj.default;
    return fontSizeObj.default;
  };

  return (
    <div className={`${className || 'manufacturing-hero-container'} hero-dynamic-height container-fluid`} style={containerStyle}>
      {!imageLoaded && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'hidden',
        }}>
          <Blurhash
            hash={blurhash}
            width="100%"
            height="100%"
            resolutionX={32}
            resolutionY={32}
            punch={1}
          />
        </div>
      )}
      <div className='manufacturing-hero-text-overlay m-0 p-0'>
        {text.map((textItem, index) => (
          <p key={index} className='manufacturing-hero-heading container-fluid' style={{
            fontSize: getFontSize(textItem.fontSize),
            fontFamily: textItem.fontFamily,
            color: textItem.color || 'white',
            fontWeight: textItem.fontWeight,
            marginTop: textItem.marginTop,
            width: width
          }}>
            {textItem.content}
          </p>
        ))}
      </div>
    </div>
  );
};

export default ManufacturingHero;