import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./blogdetail.css";
import data from "../../config/data.json";
import { Blurhash } from "react-blurhash";

const ResponsiveImage = ({ src, alt, className, hash, style }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const updateDimensions = () => {
      const img = document.createElement("img");
      img.src = src;
      img.className = className;
      Object.assign(img.style, style);
      document.body.appendChild(img);

      const { width, height } = window.getComputedStyle(img);
      setDimensions({
        width: parseInt(width, 10),
        height: parseInt(height, 10),
      });

      document.body.removeChild(img);
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [src, className, style]);

  return (
    <div style={{ position: "relative", ...style }}>
      {!loaded && (
        <Blurhash
          hash={hash}
          width={dimensions.width}
          height={dimensions.height}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      )}
      <img
        src={src}
        alt={alt}
        className={className}
        style={{ display: loaded ? "block" : "none" }}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
};

const TopicBlurhashImage = ({ src, alt, className, style, hash }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 500 });
  const imgRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        setDimensions({ width, height: 500 });
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div ref={containerRef} style={{ width: "100%" }}>
      {!imageLoaded && dimensions.width > 0 && (
        <Blurhash
          hash={hash}
          width={dimensions.width}
          height={dimensions.height}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      )}
      <img
        ref={imgRef}
        src={src}
        alt={alt}
        className={className}
        style={{
          ...style,
          display: imageLoaded ? "block" : "none",
        }}
        onLoad={() => setImageLoaded(true)}
      />
    </div>
  );
};

export default function BlogDtail() {
  const { blogId } = useParams(); // Extract blogId from URL
  const navigate = useNavigate();

  // Find the blog by ID
  const post = data.blogs.find((blog) => blog.id === blogId);
  console.log("post :", post);

  return (
    <div className="container-fluid" style={{ backgroundColor: "white" }}>
      <div className="container-fluid blog-main-section">
        <h2 className="blog-heading">{post.heading}</h2>
        <h6 className="blog-sub-heading">{post.sub_heading}</h6>
        <ResponsiveImage
          src={post.top_image}
          alt="Blog main image"
          className="blog-image"
          hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
        />

        <div className="blog-back blog-back-margin">
          <h5 className="blogger-name click grey" onClick={() => navigate(-1)}>
            Back
          </h5>
        </div>

        <div className="blogger-div">
          <img
            src={post.founder_image}
            className="blogger-image"
            alt="blogger"
          />
          <text className="blogger-name">{post.founder_name}</text>
        </div>

        <div className="blog-explanation">
          {post?.topics?.map((item, index) => (
            <>
              {index % 3 === 2 &&
                item.heading &&
                index < post.topics.length - 1 && (
                  <div className="blogger-div click">
                    <h5
                      className="blogger-name click grey"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </h5>
                  </div>
                )}
              <div className="image-box">
                {item.image && (
                  <TopicBlurhashImage
                    src={item.image}
                    alt={item.image_caption}
                    className="topic-images"
                    style={{ marginTop: 20, marginBottom: 20 }}
                    hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj"
                  />
                )}
                {item.iframe && (
                  <iframe
                    src={item.iframe}
                    title={item.sub_heading}
                    id={item.iframe}
                    alt={item.image_caption}
                    className="topic-iframe"
                    style={{
                      height: `${
                        item.iframe.indexOf("youtube") > -1 ? "500px" : "70vh"
                      }`,
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  />
                )}
                {item.image_caption && (
                  <div className="blogger-image-caption">
                    <h6 className="blogger-name">{item.image_caption}</h6>
                  </div>
                )}
              </div>

              {(item?.link || item?.image) && (
                <div className="blog-gap click">
                  {item?.link && item?.link.search("http") >= 0 ? (
                    <a
                      href={item.link}
                      className="link"
                      target={"_blank"}
                      rel={"noreferrer"}
                    >
                      {item.link_text ? item.link_text : item.link}
                    </a>
                  ) : (
                    <h5
                      className="blogger-name click link"
                      onClick={() => navigate(item.link)}
                    >
                      {item.link_text ? item.link_text : item.link}
                    </h5>
                  )}
                </div>
              )}

              {item.heading && (
                <h4 className="topic-heading">{item.heading}</h4>
              )}
              {item.sub_heading && (
                <h5 className="topic-subheading">{item.sub_heading}</h5>
              )}
              {item.paragraphs &&
                item.paragraphs.map((text) => (
                  <p className="blog-description">{text}</p>
                ))}

              {index === post.topics.length - 1 && (
                <div className="blog-back click">
                  {index === post.topics.length - 1 && (
                    <h5 className="blogger-name click">
                      <span className="grey" onClick={() => navigate(-1)}>
                        Back
                      </span>{" "}
                      <span className="grey" onClick={() => navigate("/")}>
                        Home
                      </span>
                    </h5>
                  )}
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
