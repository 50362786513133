import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import TopheroSection from "../components/topsection/TopSection";
import SmartManufacturing from "../components/manufacturing/SmartManufacturing";
import SmartLearning from "../components/learning/SmartLearning";
import Blog from "../components/blog/Blog";
import ClientCarousel from "../components/partner/Partner";
import TalkToUsSection from "../components/talktous/TalkToUsSection";
import CustomModal from "../components/modal/Modal";

const HomePage = () => {
  return (
    <div style={{ backgroundColor: "rgba(0,6,18,0.9)" }}>
      <div style={{ backgroundColor: "rgba(0,6,18,0.9)" }}>
        <Header />
        <section>
          <TopheroSection />
        </section>
        <section>
          <SmartManufacturing />
        </section>
        <section>
          <SmartLearning />
        </section>
        <section>
          <Blog />
        </section>
        <section>
          <div className="talk-to-us-home">
            <TalkToUsSection
              heading="Upgrade your operations and education with smart solutions!"
              buttonText="Lets Talk!"
            />
          </div>
          <CustomModal
            modalTitle="Lets Talk !"
            closeButtonName="Close"
            actionButtonName="Submit"
          />
        </section>
        <section>
          <ClientCarousel />
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
