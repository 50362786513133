import React from "react";
import Header from "../components/header/Header";
import HeroSection from "../components/hero/HeroSection";
import OtherHero from "../components/otherpageshero/OtherHero";
import GroupCard from "../components/card/GroupCard";
import About from "../components/about/About";
import ManufacturingHero from "../components/manufacturing/ManufacturingHero";
import WhyChooseSection from "../components/whychoose/WhyChooseSection";
import TalkToUsSection from "../components/talktous/TalkToUsSection";
import Footer from "../components/footer/Footer";
import "./page.css";
import ManufacturingAbout from "../components/about/ManufacturingAbout";
import CustomModal from "../components/modal/Modal";

const ManufacturinPage = () => {
  const cardData = [
    {
      imageUrl: require("../assets/images/manufacturing/manufacturing-img-2.png"),
      icon: "ri-brush-4-line",
      title: "Import Design",
      description:
        "Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      linkUrl: "#",
      blurhash: "LSI}-S00t7?btRt7WBWC~qD%ayxu"
    },
    {
      imageUrl: require("../assets/images/manufacturing/manufacturing-img-1.png"),
      icon: "ri-calendar-check-line",
      title: "Mark",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
      linkUrl: "#",
      blurhash: "LWONB]%M?vWAofxtRkWA~qjsD$of"
    },
    {
      imageUrl: require("../assets/images/manufacturing/manufacturing-img-3.png"),
      icon: "ri-movie-2-line",
      title: "Start Weld",
      description:
        "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
      linkUrl: "#",
      blurhash: "LYOgKO-:?cRjWCxtRkWB~qWAD$og"
    },
  ];

  const whyChooseData = {
    title: "Why Choose Terafac for Your Robotic Welding Needs",
    reasons: [
      "Enabling cost-efficient welding for individual pieces.",
      "Utilizing a modular and scalable approach.",
      "Leveraging AI for automatic parameter adjustment, visual recognition, and welding.",
      "Eliminating the need for manual programming.",
      "Boosting productivity capacity by up to fourfold.",
    ],
  };

  return (
    <div className='learning-page' style={{ backgroundColor: "rgba(0,6,18,0.9)" }}>
      <div style={{ backgroundColor: "rgba(0,6,18,0.9)" }}>
        <Header />
        <ManufacturingHero
          backgroundImage={require("../assets/images/manufacturing/manufacturing-bg-min.png")}
          backgroundColor="rgba(0, 6, 28, 0.4)"
          text={[
            {
              content: "AI Vision Platform",
              fontSize: { large: "3.5vw", default: "5.5vw", mobile: "5.5vw", tablet: "4vw" },
              fontFamily: "Audiowide",
              fontWeight: "500",
            },
            {
              content: "For Robots & Manufacturing",
              fontSize: { large: "3.5vw", default: "5.5vw", mobile: "5.5vw", tablet: "4vw" },
              fontFamily: "Audiowide",
              fontWeight: "500",
            },
          ]}
          className=""
          height={{ default: "100vh", mobile: "65vh" }}
          blurhash="LCDwmpIp8w%2~qI;KPs9?aE3Iob_"
        />
        <section>
          <div className="robot-platform">
            <ManufacturingHero
              backgroundImage={require("../assets/images/manufacturing/welding-robot-min.png")}
              backgroundColor="rgba(0, 6, 28, 0.4)"
              text={[
                {
                  content: "AI-Vision Platform for Welding Robots !",
                  fontFamily: "Roboto Slab",
                  fontWeight: "600",
                  fontSize: { large: "2.5vw", default: "2.8vw", mobile: "6vw" },
                },
                {
                  content:
                    "Terafac AI and Vision platform enables robots to autonomously scan, position, and weld, thereby eliminating the traditional dependence on robot programmers.",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  marginTop: '4%',
                  fontSize: { large: "1.5vw", default: "1.7vw", mobile: "4.4vw" },
                },
              ]}
              className="container-fluid  p-0"
              height={{ default: "42vh", mobile: "42vh" }}
              borderRadius="12px"
              border="1px solid grey"
              width="80%"
              blurhash="L4AxDf+X4T5YTxNx,m={00b_Z#*0"
            />
          </div>
        </section>
        <h2 className="point-click-heading container-fluid">
          Every Operator interface enabled by our “
          <span className="point-click-software">Point & Click Software</span>”
        </h2>
        <GroupCard cards={cardData} />
        <section>
          <ManufacturingAbout
            imageurl={require("../assets/images/smartmanufacturing-hero.png")}
            industries={[
              {
                heading: "Manufacturing SME’s – Metal Fabrication: ",
                description:
                  "Providing specialized solutions for small and medium-sized manufacturing enterprises, focusing on efficient metal fabrication processes.",
              },
              {
                heading: "Transformation: ",
                description:
                  "Guiding businesses through transformative phases with strategic support, whether it's process optimization, digitalization, or organizational restructuring.",
              },
              {
                heading: "Agriculture: ",
                description:
                  "Empowering agriculture with innovative farming equipment solutions, enhancing productivity and sustainability for farmers.",
              },
            ]}
          />
        </section>
        <section>
          <WhyChooseSection
            title={whyChooseData.title}
            reasons={whyChooseData.reasons}
          />
        </section>
        <section>
          <div className="talk-to-us-manufacturing">
            <TalkToUsSection
              heading="Reserve your welding robot demo today!"
              buttonText="Talk To Us"
            />
          </div>
          <CustomModal
            modalTitle="Talk To Us"
            closeButtonName="Close"
            actionButtonName="Submit"
          />
        </section>
        <section>
            <div className="robot-platform">
                <ManufacturingHero
                    backgroundImage={require("../assets/images/manufacturing/coming-soon.png")}
                    backgroundColor="rgba(0, 6, 28, 0.4)"
                    text={[
                    {
                        content: `"Coming Soon"`,
                        fontSize: { large: "2.5vw", default: "3.8vw", mobile: "5.6vw" },
                        fontFamily: "Roboto Slab",
                        fontWeight: "600",
                    },
                    {
                        content: "AI Manufacturing Assistant ",
                        fontSize: { large: "1.5vw", default: "2.7vw", mobile: "4vw" },
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        marginTop: 20,
                    },
                    {
                        content: "AI Quality Control Robot ",
                        fontSize: { large: "1.5vw", default: "2.7vw", mobile: "4vw" },
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        marginTop: 7,
                    },
                    ]}
                    className="container-fluid p-0 "
                    height={{ default: "42vh", mobile: "25vh" }}
                    borderRadius={"12px"}
                    border="1px solid grey"
                />
            </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};
export default ManufacturinPage;
