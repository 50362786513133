import React, { useState } from 'react';
import './modal.css'

function CustomModal({ modalTitle, closeButtonName, actionButtonName }) {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        modalTitle:modalTitle
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await postSummaryToDiscord(formData);
    };

    return (
        <>
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title ms-auto" id="staticBackdropLabel">{modalTitle}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className="col">
                                        <label htmlFor="firstName" className="form-label">First Name</label>
                                        <input type="text" className="form-control" name="firstName" id="firstName" value={formData.firstName} onChange={handleChange} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="lastName" className="form-label">Last Name</label>
                                        <input type="text" className="form-control" name="lastName" id="lastName" value={formData.lastName} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="email" className="form-control" name="email" id="email" value={formData.email} onChange={handleChange} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="phone" className="form-label">Phone Number</label>
                                    <input type="tel" className="form-control" name="phone" id="phone" value={formData.phone} onChange={handleChange} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="message" className="form-label">Message</label>
                                    <textarea className="form-control" name="message" id="message" rows="3" value={formData.message} onChange={handleChange}></textarea>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{closeButtonName}</button>
                                    <button type="submit" className="btn btn-primary">{actionButtonName}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CustomModal;



async function postSummaryToDiscord(formData) {
    try {
        const pseudoname = "chatgpt";
        const url = "https://discord.com/api/webhooks/1233360222018474036/0tvpmrNS_829AoQh2CdKy5HL-WKkW-Uby2y1uHjilvHJl31kx7z3A4hboaUVPtjdgOVD";
        const content = `Query: ${formData.modalTitle}\n\nFirst Name: ${formData.firstName}\nLast Name: ${formData.lastName}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nMessage: ${formData.message}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: pseudoname,
                content: content
            }),
        });
        const discordResponse = await response.text();
        console.log("Discord Response:", discordResponse);
    } catch (err) {
        console.error("Error posting to Discord:", err.message);
    }
}
