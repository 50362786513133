import React, { useState } from 'react';
import "./footer.css";
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {

    const [email, setEmail] = useState('');

    const postSummaryToDiscord = async (email) => {
        try {
            const pseudoname = "chatgpt";
            const url = "https://discord.com/api/webhooks/1233360222018474036/0tvpmrNS_829AoQh2CdKy5HL-WKkW-Uby2y1uHjilvHJl31kx7z3A4hboaUVPtjdgOVD";
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: pseudoname,
                    content: `New newsletter subscription from: ${email}`,
                }),
            });
            const discordResponse = await response.text();
            console.log("Discord Response:", discordResponse);
        } catch (err) {
            console.error("Error posting to Discord:", err.message);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        postSummaryToDiscord(email);
        setEmail('');
    }

    const handleNavigationClick = () => {
        // If we are already on the page, scroll to the top
        window.scrollTo(0, 0);
    };

    return (
        <footer id="footer">
            <div class="footer-top">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="footer-info">
                                <h3>Terafac</h3>
                                <p>
                                    Chandigarh, India<br></br>
                                    <strong>Phone:</strong> +91 8264445150<br />
                                    <strong>Email:</strong> info@terafac.com<br />
                                </p>
                                <div class="social-links mt-3">
                                    {/* <a href="#" class="twitter"><FaTwitter /></a> */}
                                    <a href="https://www.youtube.com/channel/UCAOV-8I7gBFdGmRJl_tgMJQ" target="_blank" class="youtube"><FaYoutube /></a>
                                    <a href="https://www.instagram.com/terafactech/" target="_blank" class="instagram"><FaInstagram /></a>
                                    <a href="https://www.linkedin.com/company/terafac-technologies-pvt-ltd/?viewAsMember=true" target="_blank" class="google-plus linkedin"><FaLinkedinIn /></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 footer-links">
                            <h4>Company</h4>
                            <ul>
                                
                                <li><i class="bx bx-chevron-right"></i> <Link onClick={() => handleNavigationClick()} className='footer-link' to={"/lab-for-college"}>Smart Learning</Link></li>
                                <li><i class="bx bx-chevron-right"></i> <Link onClick={() => handleNavigationClick()} className='footer-link' to={"/smart-manufacturing"}>Smart Manufacturing</Link></li>
                                <li><i class="bx bx-chevron-right"></i> <Link onClick={() => handleNavigationClick()} className='footer-link' to={"/contact"}>Contact Us</Link></li>
                            </ul>
                        </div>

                        <div class="col-lg-3 col-md-6 footer-links">
                            <h4>Quick Links</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i> <Link className='footer-link' onClick={() => handleNavigationClick()} to={"/smart-manufacturing"}>Smart Manufacturing</Link></li>
                                <li><i class="bx bx-chevron-right"></i> <Link className='footer-link' onClick={() => handleNavigationClick()} to={"/lab-for-college"}>Lab for College / University</Link></li>
                                <li><i class="bx bx-chevron-right"></i> <Link className='footer-link' onClick={() => handleNavigationClick()} to={"/lab-for-school"}>Lab for 6th-12th Grade</Link></li>
                               
                            </ul>
                        </div>

                        <div class="col-lg-3 col-md-6 footer-newsletter">
                            <h4>Our Newsletter</h4>
                            <p>Revolutionizing the Future: Your Update on Smart Industry Insights</p>
                            <form onSubmit={handleSubmit}>
                                <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                <input type="submit" value="Subscribe" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="copyright">
                    &copy; Copyright <strong><span>Terafac</span></strong>. All Rights Reserved
                </div>
            </div>
        </footer>
    )
}
export default Footer;