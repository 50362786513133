import React from 'react';
import "./gallery.css";

const Gallery = ({ images }) => {
    return (
        <div id="gallery" className="gallery">
            <div className="container-fluid" data-aos="fade-up">
                <div className="row gy-3" data-aos="fade-up" data-aos-delay="100">
                    {images.map((imageURL, index) => (
                        <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                            <div className="gallery-item">
                                <img src={imageURL} className="img-fluid gallery-image" alt={`Gallery item ${index + 1}`} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default Gallery;
