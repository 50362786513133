import React from 'react';
import './blog.css';
import { useNavigate } from 'react-router-dom';
import data from "../../config/data.json";

const BlogPost = () => {
    let navigate = useNavigate();
    const blogPosts = data.blogs;

    // Function to get the first paragraph from the topics and truncate it to 200 characters
    const getFirstParagraph = (topics) => {
        const firstTopic = topics[0]; // Assuming we want the first topic's first paragraph
        const firstParagraph = firstTopic.paragraphs ? firstTopic.paragraphs[0] : "";
        return firstParagraph.length > 200 ? firstParagraph.slice(0, 129) + "..." : firstParagraph;
    };

    return (
        <div className="main-blog-container" id='blog'>
            <h2 className='top-level-heading'>Read our blog</h2>
            <div className="blogPostContainer container-fluid">
                {blogPosts.map((post) => (
                    <div key={post.id} onClick={() => navigate(`/blog/${post.id}`)} className="blogPost">
                        <div className="blogHeaderPost">
                            <img src={post.top_image} alt={post.heading} className='img-fluid' />
                        </div>
                        <div className="blogBodyPost">
                            <div className="blogPostContent">
                                <h1>{post.heading}</h1>
                                <p>{getFirstParagraph(post.topics)}</p>
                                <div className="blogContainerInfos">
                                    <button
                                        className="blogPostedBy read-more-button"
                                    >
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogPost;
