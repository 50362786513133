import React from 'react';
import "./labsection.css"

const LabSection = ({ cards }) => {
    return (
        <div id="lab-about-boxes" className="lab-about-boxes">
            <div className="container-fluid" data-aos="fade-up">
                <div className="row">
                    {cards.map((card, index) => (
                        <div key={index} className="col-lg-3 col-md-6" data-aos-delay={`${100 + index * 100}`}> {/* Changed to prefixed "lab-col" classes */}
                            <div>
                                <div className="lab-card-body">
                                    <h5 className="text-white p-0 m-0"><p className='lab-card-title p-0 m-0'>{card.title}</p></h5> {/* Removed redundant "text-center" class as it should be in CSS */}
                                </div>
                                <img src={card.imageUrl} className="card-img-top  lab-card-image" id='lab-card-image' alt="..." />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
export default LabSection;
