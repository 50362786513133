import React from 'react'
import OtherHero from '../components/otherpageshero/OtherHero';
import Header from '../components/header/Header';
import ManufacturingHero from '../components/manufacturing/ManufacturingHero';
import TalkToUsSection from '../components/talktous/TalkToUsSection';
import WhyChooseSection from '../components/whychoose/WhyChooseSection';
import GroupCard from '../components/card/GroupCard';
import LearningAbout from '../components/about/LearningAbout';
import Footer from '../components/footer/Footer';
import CustomModal from '../components/modal/Modal';
import './page.css';

const LearningPage = () => {

    const whyChooseData = {
        title: "Technologies covered ",
        reasons: [
            "Automation: Enhancing efficiency through streamlined processes.",
            "Robotics: Designing intelligent machines for various tasks.",
            "Industry 4.0: Revolutionizing manufacturing with advanced technologies.",
            "IoT: Connecting devices for data-driven insights and automation.",
            "Embedded Systems: Tailoring computing systems for specific functions.",
            "AI: Empowering machines to learn and make autonomous decisions."
        ]
    };
    const cardData = [
        {
            imageUrl: require("../assets/images/all-in-one.png"),
            icon: "ri-brush-4-line",
            title: "All in One",
            description: "Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            linkUrl: "#",
            blurhash: "LbIhy@Ri?Ht8_Ns.s:of~qt7oeoz"
        },
        {
            imageUrl: require("../assets/images/hands-on-learning.jpg"),
            icon: "ri-calendar-check-line",
            title: "Hands on Learning",
            description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
            linkUrl: "#",
            blurhash: "LbNT|5t5~VkCx]t6M_WX-:t7NHRj"
        },
        {
            imageUrl: require("../assets/images/versatility.png"),
            icon: "ri-movie-2-line",
            title: "Versatility",
            description: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
            linkUrl: "#",
            blurhash: "LXE{,_D%~qIUIUV@R*M{-qNFt6V@"
        }
    ];
    return (
        <div className='learning-page' style={{ backgroundColor: "rgba(0,6,18,0.9)" }}>
            <div style={{ backgroundColor: 'rgba(0,6,18,0.9)' }}>
                <Header />
                <ManufacturingHero
                    backgroundImage={require("../assets/images/learning/learning-hero-bg.png")}
                    text={[
                        {
                            content: "Plug-n-Play Mini-Factory For Schools",
                            fontSize: { large: "3.5vw", default: "5.5vw", mobile: "5.5vw", tablet: "4vw" },
                            fontFamily: "Audiowide", fontWeight: '500'
                        },
                    ]}
                    className=""
                    blurhash='LNN1Dloc~qt7?bt7Rjof?bxuRjju'
                />
                <section>
                    <div className="robot-platform">
                        <ManufacturingHero
                            backgroundImage={require("../assets/images/learning/learning-unlease.png")}
                            text={[
                                {
                                    content: "Unleash the Power of Industry 4.0 in your Classroom!",
                                    fontSize: { large: "2.5vw", default: "3.6vw", mobile: "6vw" },
                                    fontFamily: "Roboto Slab",
                                    fontWeight: '700'
                                },
                            ]}
                            className="container-fluid  p-0"
                            height={{ default: "42vh", mobile: "42vh" }}
                            borderRadius="12px"
                            width={{ default: "68%", mobile: "100%" }}
                            blurhash='L7Fr#7D$D$%M~qMx%Mj[RjxuWA4n'
                        />
                    </div>
                </section>
                <section>
                    <div className="mini-factory-div">
                    <LearningAbout
                        imageurl={require("../assets/images/all-in-one.png")}
                        heading="We equip classrooms with plug-n-play Mini-Factory that enables hands-onlearning of Industry 4.0 technologies. "
                        description="Founded with a vision to empower smart manufacturing and smart learning, Terafac Technologies was established in 2021. The company is a DPIIT recognized startup with the aim to prepare the youth for Industry 4.0. The world is going through what we call the Fourth Industrial Revolution. The world is set to see a transformation that will be unmatched in scale, scope and complexity, with stakeholders from all walks of life from public and private sectors to academia and civil society."
                    />
                    </div>
                </section>
                <section>
                    <div className="home-card">
                        <GroupCard
                            cards={cardData}
                        />
                    </div>
                </section>
                <section>
                    <div className="talk-to-us-manufacturing">
                        <TalkToUsSection
                            heading="Reserve your school's personalized demo now!"
                            buttonText="Book Demo!"
                        />
                    </div>
                     <CustomModal
                modalTitle="Perfect for schools, Book Demo Now"
                closeButtonName="Close"
                actionButtonName="Book Demo"
            />
                </section>
                <section>
                    <WhyChooseSection
                        title={whyChooseData.title}
                        reasons={whyChooseData.reasons}
                    />
                </section>
                <Footer />
            </div>
        </div>
    )
}

export default LearningPage;